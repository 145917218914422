const TokenService = {
  getLocalRefreshToken: () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.refreshToken;
  },

  getLocalAccessToken: () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.accessToken;
  },

  updateLocalAccessToken: (accesstoken) => {
    const token = JSON.parse(localStorage.getItem("token"));
    token.accessToken = accesstoken;
    localStorage.setItem("token", JSON.stringify(token));
  },
  updateLocalRefreshToken: (refreshToken) => {
    let token = JSON.parse(localStorage.getItem("token"));
    token.refreshToken = refreshToken;
    localStorage.setItem("token", JSON.stringify(token));
  },
  getToken: () => {
    return JSON.parse(localStorage.getItem("token"));
  },

  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  },

  removeToken: () => {
    localStorage.removeItem("token");
  }
};

export default TokenService;
