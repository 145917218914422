import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API_CONFIG from "../../../../config/apiConfig";
import { instanceAxios } from "../../../../app/axios";

export const listUserNameApi = createAsyncThunk("/listUserName", async () => {
  try {
    const response = await instanceAxios.get(API_CONFIG.userManager.getAllUser);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
});

export const listUserNameSlice = createSlice({
  name: "list-username-slice",
  initialState: {
    arrUserName: [],
    isLoading: false,
    errorUserName: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listUserNameApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listUserNameApi.fulfilled, (state, action) => {
        state.arrUserName = action.payload.data.system;
        state.isLoading = false;
        state.errorUserName = "";
      })
      .addCase(listUserNameApi.rejected, (state) => {
        state.isLoading = false;
        state.errorUserName = "Loi call Name";
      });
  },
});

export default listUserNameSlice.reducer;
