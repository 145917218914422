import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { setPageActiveQuotation, setResetConditionSearch } from '../features/common/quotation-invoice'

const useReSetConditionQuotation = () => {
  const dispatch = useDispatch();
  const matchQuotationConfirm = useMatch("/app/quotation/:id/confirm");
  const matchQuotationEdit = useMatch("/app/quotation/:id/edit");
  const matchQuotationList = useMatch("/app/quotation/")
  
  useEffect(() => {
    if (!matchQuotationConfirm && !matchQuotationEdit && !matchQuotationList) {
      dispatch(setPageActiveQuotation(1))
      dispatch(setResetConditionSearch())
    }
  }, [dispatch, matchQuotationEdit, matchQuotationConfirm, matchQuotationList])
}

export default useReSetConditionQuotation