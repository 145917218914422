import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./table.css";
import App from "./App";
import "./ui.css";
import "./ui_cadewa.css"
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./app/store";
import { Provider } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";
import ErrorBoundaryContent from "./containers/ErrorBoundaryContent";
import { QueryClient, QueryClientProvider } from "react-query";

import "./features/language/i18n";
import { STALE_TIME } from "./utils/globalConstantUtil";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      suspense: true,
      staleTime: STALE_TIME
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={<ErrorBoundaryContent />}>
        <Suspense fallback={<SuspenseContent />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  </Router>
  // </React.StrictMode>
);

// reportWebVitals();
