import { cn } from "../../utils/cn";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import Subtitle from "../Typography/Subtitle";
import { useTranslation } from "react-i18next";

function PageCard({ children, TopSideButtons, pageKind, className}) {
  const { t } = useTranslation();

  const getOuterClass = () => {
    if(pageKind === "list_with_button") {
      return "page_card_outer_list_with_primary_button";
    }
    return "page_card_outer";
  }

  return (
    <>
      <div className={getOuterClass()}>
        {/* Title for Card */}
        <div className="flex items-center justify-between">
          {/* Top side button, show only if present */}
          {TopSideButtons && <div className="inline-block float-right">{TopSideButtons}</div>}
        </div>

        {/** Card Body */}
        <div className={cn("h-full w-full bg-base-100 p-5", className)}>{children}</div>
      </div>
    </>
  );
}

export default PageCard;
