import { createSlice } from "@reduxjs/toolkit";
import { ITEM_TYPE } from "../../../utils/globalConstantUtil";

export const stateAdvancedSlice = createSlice({
  name: "stateAdvanced",
  initialState: {
    [ITEM_TYPE.INPUT]:[],
    [ITEM_TYPE.DATE]: [],
    [ITEM_TYPE.SELECT]: [],
    [ITEM_TYPE.RADIO_BUTTON]: [],
    [ITEM_TYPE.CHECKBOX]: [],
    [ITEM_TYPE.EMPLOYEE]: [],
    [ITEM_TYPE.DEPARTMENT]: [],
    [ITEM_TYPE.CUSTOMER]: [],
    [ITEM_TYPE.JOIN_MEMBER]: [],
    [ITEM_TYPE.CONST_MASTER]: [],
    [ITEM_TYPE.CONTRACT_TYPE]:[],
    [ITEM_TYPE.ROOM]: [],
    [ITEM_TYPE.STATUS]: [],
    [ITEM_TYPE.RELATED_BUKKEN]: [],
    [ITEM_TYPE.ADDRESS]: [],
    [ITEM_TYPE.QUALIFICATION]: [],
    [ITEM_TYPE.PHONE]: [],
    [ITEM_TYPE.SEKO_EMPLOYEE]: [],

  },
  reducers: {
    setInputType: (state, action) => {
      state[ITEM_TYPE.INPUT] = action.payload;
    },
    setDatePickerType: (state, action) => {
      state[ITEM_TYPE.DATE] = action.payload;
    },
    setDropDownType: (state, action) => {
      state[ITEM_TYPE.SELECT] = action.payload;
    },
    setRadioType: (state, action) => {
      state[ITEM_TYPE.RADIO_BUTTON] = action.payload;
    },
    setEmployeeInputType: (state, action) => {
      state[ITEM_TYPE.EMPLOYEE] = action.payload;
    },
    setCheckBoxType: (state, action) => {
      state[ITEM_TYPE.CHECKBOX] = action.payload;
    },
    setDepartmentInputType: (state, action) => {
      state[ITEM_TYPE.DEPARTMENT] = action.payload;
    },
    setCustomerInputType: (state, action) => {
      state[ITEM_TYPE.CUSTOMER] = action.payload;
    },
    setJoinMemberInputType: (state, action) => {
      state[ITEM_TYPE.JOIN_MEMBER] = action.payload;
    },
    setConstMasterInputType: (state, action) => {
      state[ITEM_TYPE.CONST_MASTER] = action.payload;
    },
    setContractInputType: (state, action) => {
      state[ITEM_TYPE.CONTRACT_TYPE] = action.payload;
    },
    setRoomInputType: (state, action) => {
      state[ITEM_TYPE.ROOM] = action.payload;
    },
    setStatusType: (state, action) => {
      state[ITEM_TYPE.STATUS] = action.payload;
    },
    setRelatedInputType: (state, action) => {
      state[ITEM_TYPE.RELATED_BUKKEN] = action.payload;
    },
    setAddressInputType: (state, action) => {
      state[ITEM_TYPE.ADDRESS] = action.payload;
    },
    setQuolificationInputType: (state, action) => {
      state[ITEM_TYPE.QUALIFICATION] = action.payload;
    },
    setPhoneType: (state, action) => {
      state[ITEM_TYPE.PHONE] = action.payload;
    },
    setSekoEmployeeType: (state, action) => {
      state[ITEM_TYPE.SEKO_EMPLOYEE] = action.payload;
    },
    

    reset: (state) => {
      state[ITEM_TYPE.INPUT] = [];
      state[ITEM_TYPE.DATE] = [];
      state[ITEM_TYPE.SELECT] = [];
      state[ITEM_TYPE.RADIO_BUTTON] = [];
      state[ITEM_TYPE.CHECKBOX] = [];
      state[ITEM_TYPE.EMPLOYEE] = [];
      state[ITEM_TYPE.DEPARTMENT] = [];
      state[ITEM_TYPE.CUSTOMER] = [];
      state[ITEM_TYPE.JOIN_MEMBER] = [];
      state[ITEM_TYPE.CONST_MASTER] = [];
      state[ITEM_TYPE.CONTRACT_TYPE] = [];
      state[ITEM_TYPE.ROOM] = [];
      state[ITEM_TYPE.STATUS] = [];
      state[ITEM_TYPE.RELATED_BUKKEN] = [];
      state[ITEM_TYPE.ADDRESS] = [];
      state[ITEM_TYPE.QUALIFICATION] = [];
      state[ITEM_TYPE.PHONE] = [];
      state[ITEM_TYPE.SEKO_EMPLOYEE] = [];
    }
  }
});

export const {
  setInputType,
  setDatePickerType,
  setDropDownType,
  setRadioType,
  setCheckBoxType,
  setEmployeeInputType,
  setDepartmentInputType,
  setCustomerInputType,
  setJoinMemberInputType,
  setConstMasterInputType,
  setContractInputType,
  setRoomInputType,
  setStatusType,
  setRelatedInputType,
  setAddressInputType,
  setQuolificationInputType,
  setPhoneType,
  setSekoEmployeeType,
  reset
} = stateAdvancedSlice.actions;

export default stateAdvancedSlice.reducer;
