import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { setPageActiveInvoice, setResetConditionSearch } from '../features/common/quotation-invoice'

const useReSetConditionInvoice = () => {
  const dispatch = useDispatch();
  const matchInvoiceDetail = useMatch("/app/invoice/:id/detail");
  const matchInvoiceEdit = useMatch("/app/invoice/:id/edit");
  const matchInvoiceList = useMatch("/app/invoice/")
  
  useEffect(() => {
    if (!matchInvoiceDetail && !matchInvoiceEdit && !matchInvoiceList) {
      dispatch(setPageActiveInvoice(1))
      dispatch(setResetConditionSearch())
    }
  }, [dispatch, matchInvoiceEdit, matchInvoiceDetail, matchInvoiceList])
}

export default useReSetConditionInvoice