import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    accessToken: undefined,
    refreshToken: undefined,
    userType: undefined,
    version: undefined,
  },
  reducers: {
    setLoginToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userType = action.payload.userType;
      state.version = process.env.REACT_APP_VERSION;
    },
  },
});

export const {
  setLoginToken
} = loginSlice.actions;

export default loginSlice.reducer;
