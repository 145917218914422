import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { setPageActivePhotobook } from '../features/common/bukkenSlice/stateBukken'

const useResetPagePhotobook = () => {
  const dispatch = useDispatch();
  const matchIPhotobookDetail = useMatch("/app/photobook/:idBukken/detail-photobook/:idPhotobook");
  const matchIPhotobookEdit = useMatch("/app/photobook/:idBukken/edit-photobook/:idPhotobook");
  const matchIPhotobookList = useMatch("/app/photobook/:idBukken/photobooks")
  
  useEffect(() => {
    if (!matchIPhotobookDetail && !matchIPhotobookEdit && !matchIPhotobookList) {
      dispatch(setPageActivePhotobook(1))
    }
  }, [dispatch, matchIPhotobookEdit, matchIPhotobookDetail, matchIPhotobookList])
}

export default useResetPagePhotobook