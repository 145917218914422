import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import languageSlice from "../features/common/languageSlice";
import stateModalSlice from "../features/common/bukkenSlice/stateModalSlice";
import stateBukkenSlice from "../features/common/bukkenSlice/stateBukken";
import stateAdvancedInfo from "../features/common/bukkenSlice/stateAdvancedInfo";
import stateLoginInfo from "../features/common/loginSlice";
import statePatternSlice from "../features/common/bukkenSlice/statePatternItem";
import syncSlice from "../features/common/syncSlice";
import {
  listCompanySlice,
  companyInfoSlice,
  listUsernameSlice,
  folderChildrenSlice,
} from "../features/common/file-storage-vaix";
import stateQuotationInvoiceSlice from "../features/common/quotation-invoice";

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  language: languageSlice,
  listCompany: listCompanySlice,
  companyInfo: companyInfoSlice,
  listUserName: listUsernameSlice,
  folderChildren: folderChildrenSlice,
  stateModal: stateModalSlice,
  stateBukken: stateBukkenSlice,
  stateAdvancedInfo: stateAdvancedInfo,
  stateLoginInfo: stateLoginInfo,
  statePattern: statePatternSlice,
  sync: syncSlice,
  stateQuotationInvoice: stateQuotationInvoiceSlice
};

export default configureStore({
  reducer: combinedReducer,
  // config to ignore non-serializable warnings.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
