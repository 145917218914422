import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs"
import { DEFAULT_STATUS_UUIDS } from "../../../utils/globalConstantUtil"

const initialSearchAdvancedQuotation = {
  quotation_info: ""
  , bukken_info: ""
  , quotation_state: DEFAULT_STATUS_UUIDS.quotation
  , submit_name: ""
  , quotation_create_from_date: ""
  , quotation_create_to_date: ""
  , quotation_expiration_from_date: dayjs().startOf('day').format("YYYYMMDDHHmmss")
  , quotation_expiration_to_date: dayjs().startOf('day').add(1, "month").format("YYYYMMDDHHmmss")
  , quotation_create_limit_from_date: ""
  , quotation_create_limit_to_date: ""
  , total_amount_from: ""
  , total_amount_to: ""
  , manager_name: null
  , representative_flg: false
}

const initialSearchAdvancedInvoice = {
  invoice_info: ""
  , bukken_info: ""
  , invoice_status: DEFAULT_STATUS_UUIDS.invoice
  , billing_company_name: ""
  , billing_dt_from_date: ""
  , billing_dt_to_date: ""
  , limit_dt_from_date: dayjs().startOf('day').format("YYYYMMDDHHmmss")
  , limit_dt_to_date: dayjs().startOf('day').add(1, "month").format("YYYYMMDDHHmmss")
  , payment_dt_from_date: ""
  , payment_dt_to_date: ""
  , total_amount_from: ""
  , total_amount_to: ""
  , manager_name: null
}

const initialSearchBasic = {
  free_keyword: null,
  own_manager_name: null,
  from_dt: null,
  to_dt: null
}

export const stateQuotationInvoiceSlice = createSlice({
  name: "stateQuotationInvoice",
  initialState: {
    pageActiveQuotation: 1,
    pageActiveInvoice: 1,
    bodySearchQuotation: {},
    bodySearchInvoice: {},
    searchBasic: {
      ...initialSearchBasic
    },
    searchAdvancedInvoice: {
      ...initialSearchAdvancedInvoice
    },
    searchAdvancedQuotation: {
      ...initialSearchAdvancedQuotation
    },
    isSearchAdvanced: false
  },
  reducers: {
    setPageActiveQuotation: (state, action) => {
      state.pageActiveQuotation = action.payload;
    },
    setPageActiveInvoice: (state, action) => {
      state.pageActiveInvoice = action.payload;
    },
    setConditionQuotation: (state, action) =>{
      state.bodySearchQuotation = action.payload
    },
    setConditionInvoice: (state, action) =>{
      state.bodySearchInvoice = action.payload
    },

    setSearchBasic: (state, action) => {
      state.searchBasic = action.payload
    },
    setAdvancedInvoice: (state, action) => {
      state.searchAdvancedInvoice = action.payload
    },
    setAdvancedQuotation: (state, action) => {
      state.searchAdvancedQuotation = action.payload
    },
    setIsSearchAdvanced: (state, action) => {
      state.isSearchAdvanced = action.payload
    },
    setResetConditionSearch: (state) => {
      state.bodySearchInvoice = {}
      state.bodySearchQuotation = {}
      state.searchAdvancedInvoice = {...initialSearchAdvancedInvoice}
      state.searchAdvancedQuotation = {...initialSearchAdvancedQuotation}
      state.searchBasic = {...initialSearchBasic}
      state.isSearchAdvanced = false
    }
  }
});

export const {
  setPageActiveQuotation,
  setPageActiveInvoice,
  setConditionQuotation,
  setConditionInvoice,
  setSearchBasic,
  setAdvancedInvoice,
  setAdvancedQuotation,
  setIsSearchAdvanced,
  setResetConditionSearch
} = stateQuotationInvoiceSlice.actions;

export default stateQuotationInvoiceSlice.reducer;
