import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    pageTitle: "Home", // current page title state management
    noOfNotifications: 15, // no of unread notifications
    newNotificationMessage: "", // message of notification to be shown
    newNotificationStatus: 1, // to check the notification type -  success/ error/ info,
    error: "" || {},
    success: "",
    headerType: 1,
    leftSideBarVisible: true
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload.title;
      state.headerType = action.payload?.headerType ?? 1;
      state.leftSideBarVisible = action.payload?.leftSideBarVisible ?? state.leftSideBarVisible;
    },

    removeNotificationMessage: (state, action) => {
      state.newNotificationMessage = "";
    },

    showNotification: (state, action) => {
      state.newNotificationMessage = action.payload.message;
      state.newNotificationStatus = action.payload.status;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success = action.payload;
    },
    setLeftSideBarVisible: (state, action) => {
      state.leftSideBarVisible = action.payload?.leftSideBarVisible ?? true;
    },

  },
});

export const {
  setPageTitle,
  removeNotificationMessage,
  showNotification,
  setErrorMessage,
  setSuccessMessage,
  setLeftSideBarVisible
} = headerSlice.actions;

export default headerSlice.reducer;
