import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    title: "", // current  title state management
    isOpen: false, // modal state management for opening closing
    bodyType: "", // modal content management
    size: "", // modal content management
    extraObject: {},
    identifier: "",
    inputValue:"",
    userAnswer :false,
    changeFileData: null, // data temp to update state
    changeFolderData: null, // data temp to update state
    newFolderData: null, // data temp to update state
    newMasterData: null, // data temp to update state
    changeMasterData: null, // data temp to update state
    deleteFileData: null, // data temp to delete file
    deleteFolderData: null, // data temp to delete folder
    deleteImageData: null,
    deleteMultiData: null, // data temp to delete multi
    deleteItemClassMasteData: null
  },
  reducers: {
    openModal: (state, action) => {
      const { title, bodyType, extraObject, size, identifier } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.title = title;
      state.size = size || "md";
      state.extraObject = extraObject;
      state.identifier = identifier || "";
    },
    closeModal: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.extraObject = {};
      state.identifier = "";
      state.inputValue="";
    },
    closeModalForOK: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.extraObject = action.payload?.extraObject ?? {};
      state.userAnswer=true;
    },
    closeModalForNo: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.userAnswer=false;
      // state.extraObject = action.payload?.extraObject ?? {};
    },
    setTextInputModalValue:(state, action) => {
      state.inputValue =action.payload;
    },
    setChangeFileData: (state, action) => {
      state.changeFileData = action.payload;
    },
    setChangeFolderData: (state, action) => {
      state.changeFolderData = action.payload;
    },
    setNewFolderData: (state, action) => {
      state.newFolderData = action.payload;
    },
    setNewMasterData: (state, action) => {
      state.newMasterData = action.payload;
    },
    setChangeMasterData: (state, action) => {
      state.changeMasterData = action.payload;
    },
    setDeleteFileData: (state, action) => {
      state.deleteFileData = action.payload;
    },
    setDeleteFolderData: (state, action) => {
      state.deleteFolderData = action.payload;
    },
    setDeleteImageData: (state, action) => {
      state.deleteImageData = action.payload;
    },
    setDeleteMultiFolderData: (state, action) => {
      state.deleteMultiData = action.payload;
    },
    setDeleteItemMasterData: (state, action) => {
      state.deleteItemMasteData = action.payload;
    }
  }
});

export const {
  openModal,
  closeModal,
  closeModalForOK,
  closeModalForNo,
  setTextInputModalValue,
  setChangeFileData,
  setChangeFolderData,
  setNewFolderData,
  setNewMasterData,
  setChangeMasterData,
  setDeleteFileData,
  setDeleteFolderData,
  setDeleteImageData,
  setDeleteMultiFolderData,
  setDeleteItemMasterData
} = modalSlice.actions;

export default modalSlice.reducer;
