import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_PROGRESS_CONDITION } from "../../../utils/globalConstantUtil"
import { createLikeCondition } from "../../../utils/bukken/helpers/handlePayloadBukkenSearch"

export const stateBukkenSlice = createSlice({
  name: "stateBukken",
  initialState: {
    bukkenName: "",
    bukkenUuid: "",
    bukkenClassUuid: "",
    patterNameActive: "",
    isOpenDropdown: false,
    isAddNewBukken: false,
    progressCondition: INITIAL_PROGRESS_CONDITION,
    pageActive: 1,
    pageActivePhotobook: 1,
    payloadBukkenSearch: {
      info: {
        progress: INITIAL_PROGRESS_CONDITION.map(createLikeCondition)
      }
    },
  },
  reducers: {
    setBukkenClassUuid: (state, action) => {
      state.bukkenClassUuid = action.payload;
    },
    setIsOpenDropdown: (state) => {
      state.isOpenDropdown = !state.isOpenDropdown;
    },
    setBukkenUuid: (state, action) => {
      state.bukkenUuid = action.payload;
    },
    setBukkenName: (state, action) => {
      state.bukkenName = action.payload;
    },
    setPatternActive: (state, action) => {
      state.patterNameActive = action.payload;
    },
    setAddNewItem: (state, action) => {
      state.isAddNewBukken = action.payload;
    },
    setProgressCondition: (state, action) => {
      state.progressCondition = action.payload;
    },
    setPageActive: (state, action) => {
      state.pageActive = action.payload;
    },
    setPayloadBukkenSearchStore: (state, action) => {
      state.payloadBukkenSearch = action.payload;
    },
    setPageActivePhotobook: (state, action) => {
      state.pageActivePhotobook = action.payload;
    }
  }
});

export const {
  setBukkenClassUuid,
  setIsOpenDropdown,
  setBukkenUuid,
  setBukkenName,
  setPatternActive,
  setAddNewItem,
  setProgressCondition,
  setPageActive,
  setPayloadBukkenSearchStore,
  setPageActivePhotobook
} = stateBukkenSlice.actions;

export default stateBukkenSlice.reducer;
