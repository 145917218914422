import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationJA from "./ja/content.json";
import messageJA from "./ja/message.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
      },
      ja: {
        translation: translationJA,
        message: messageJA
      }
    }
  });
/*
import translationEN from "./admin/locales/en.json";
import translationJA from "./admin/locales/ja.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN
      },
      ja: {
        translation: translationJA
      }
    }
  });
*/


export default i18n;
